import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router";

import dayjs from "dayjs";
import "dayjs/locale/en";
import * as dayjsLocale from "dayjs/locale/es-pr";
import * as antdLocale from "antd/locale/es_ES";

import {
  Scheduler,
  SchedulerData,
  ViewType,
  DnDSource,
  DATE_FORMAT,
  wrapperFun,
} from "react-big-schedule";
import "react-big-schedule/dist/css/style.css";

import { t, Trans } from "@lingui/macro";
import { Input, Pagination, Flex, Col, Row, Avatar } from "antd";
import { Nav, NavItem, NavLink, Table, Spinner, Button } from "reactstrap";
import { debounce, forEach } from "lodash";

import queryString from "query-string";

import useSearchParams from "../../util/useSearchParams";

import UWEEntityApi from "../../api/UWEEntityApi";

import leftPad from "../../util/leftPad";

import { FilterForm, filterFormPropertySchema, Color } from "./util";

import "../../scss/_scheduler.scss";
import SearchBar from "../Search/SearchBar";

const { TextArea, Search } = Input;
const { ColumnGroup, Column } = Table;

// ======================
// = SCHEDULER CONFIG!!!
// ======================
const schedulerFormatConfig = {
  schedulerWidth: "93%",
  defaultEventBgColor: "#F19A33",
  dayStartFrom: 8,
  dayStopTo: 22,
  dayCellWidth: 37,

  // nonWorkingTimeBodyBgColor: 'white',
  // dayResourceTableWidth: 160,
  // weekResourceTableWidth: '16%',
  views: [
    {
      viewName: "Day",
      viewType: ViewType.Day,
      showAgenda: false,
      isEventPerspective: false,
    },
    {
      viewName: "Week",
      viewType: ViewType.Week,
      showAgenda: false,
      isEventPerspective: false,
    },
  ],

  // Disabled for now due to dayjs clone.localeData == null error
  calendarPopoverEnabled: false,

  // Settings for locations dashboard... enable for others possibly
  startResizable: false,
  endResizable: false,

  // Conflicts with search bar
  dateChangeSpinEnabled: false,

  nonWorkingTimeHeadColor: "#000000",
  nonWorkingTimeHeadBgColor: "#f3f5f6",
  nonWorkingTimeBodyBgColor: "#f3f5f6",
};

const mainSchedulerConfig = {
  ...schedulerFormatConfig,
  resourceName: "Users",
  taskName: "Shift",
  schedulerContentHeight: "20%"
};

const openSchedulerConfig = {
  ...schedulerFormatConfig,
  resourceName: "Open Slots",
  taskName: "Shift",
  schedulerContentHeight: "10%",
};

// Unassigned Resource
const UNASSIGNED_RESOURCE_ID = -1;

function ShiftScheduler({ element }) {
  if (!element?.filters) {
    element.filters = [];
  }

  // Get props
  const { entityType, startDate, endDate } = element;
  // Routing
  const history = useHistory();

  // Determine dashboard type
  const isUsersShiftTray = window.location.href.includes("users-shifts");

  /*
   * =====================================
   * = DASHBOARD PROPS PARSING+PROCESSING
   * =====================================
   */
  // Get start and end dates
  const actualStartDate =
    startDate ||
    dayjs()
      .subtract(1, "month")
      .format("YYYY-MM-DD");
  const actualEndDate =
    endDate ||
    dayjs()
      .add(2, "week")
      .format("YYYY-MM-DD");

  // Get query params
  const [[id, setId]] = useSearchParams({
    id: null,
  });

  // Get entity info from id query param
  const [entityData, setEntityData] = useState(null);

  const fetchEntityData = async () => {
    if (!id) {
      return null;
    }
    const data = await UWEEntityApi.getUWEEntity({ entityId: id });
    return data;
  };

  useEffect(() => {
    const bootstrapAsync = async () => {
      const data = await fetchEntityData();
      setEntityData(data);
    };
    bootstrapAsync();
  }, [id]);

  // Get entity name
  const entityName = useMemo(() => {
    let entity = entityType;
    if (Array.isArray(entity)) {
      entity = entity.join(",");
    }
    return entity;
  }, [entityType]);

  /*
   * ===================================
   * = RESOURCE FETCHING & PAGINATION
   * ===================================
   */

  // - Shifts
  const shiftPageSize = useMemo(() => 3000); // Set more sensible default later...

  // Users (resources) pagination
  const [userPageSize, setUserPageSize] = useState(10);
  const [userCurrentPageNumber, setUserCurrentPageNumber] = useState(1);
  const [userTotal, setUserTotal] = useState(0);

  // Fetched resources
  const [events, setEvents] = useState([]); // from shifts
  const [resources, setResources] = useState([]); // from users

  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingResources, setIsLoadingResources] = useState(false);

  // Values derived from fetched resource
  const [isActive, setIsActive] = useState(false);

  // --------------------
  // - Fetch shift data
  // --------------------
  // Fetch the shift data from UWE
  const fetchShifts = useCallback(
    async (props) => {
      const resource = `uwe-entities/${entityName}/list`;
      if (!resource) return [];
      const payload = {
        resource,
        offset: 0,
        size: shiftPageSize,
        "metadata-date-greaterthanorequal-shiftDate": props?.startDate?.format(
          "YYYY-MM-DD"
        ),
        "metadata-date-lessthanorequal-shiftDate": props?.endDate?.format(
          "YYYY-MM-DD"
        ),
        "tray": window.location.href.split("/")[4],
      };

      if (!isUsersShiftTray) {
        payload["metadata-eq-location"] = id.toString();
      }

      const data = await UWEEntityApi.getWorkTray(payload);
      return data;
    },
    [shiftPageSize, entityName, id]
  );

  useEffect(() => {
    const bootstrapAsync = async () => {
      if (!isUsersShiftTray && !id) {
        return;
      }
      setIsLoadingEvents(true);

      const today = dayjs();
      const startDate = today.startOf("week").add(0, "day");
      const endDate = today.startOf("week").add(6, "day");

      const { from, to, count, items } = await fetchShifts({
        startDate,
        endDate,
      });
      // Fetch the shift allocations using cached load HERE!
      // Get the scheduler events
      const events = getEvents(items);

      // Check if location is active:
      // The location is active if not all events are in the past (i.e. some are in the present/future)
      const now = dayjs();
      const active = events.some((e) => {
        return dayjs(e.end).isAfter(now);
      });

      setIsActive(active);
      setEvents(events);
      setIsLoadingEvents(false);
    };
    bootstrapAsync();
  }, [id]);

  // ------------------
  // - Fetch Users
  // ------------------
  const [filterFormValues, setFilterFormValues] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [locationUsersId, setLocationUsersId] = useState(null);

  const fetchUsers = async (offset, size) => {
    let payload = {
      resource: "uwe-entities/Users/list",
      offset,
      size,
      sortBy: "date",
      ...filterFormValues,
    };

    // Only apply filter when in Location Shifts dashboard.
    if (!isUsersShiftTray) {
      payload.resource = "uwe-entities/LocationUsers/list";
      payload["metadata-locationId"] = entityData?.id || "0";
      const data = await UWEEntityApi.getWorkTray(payload);
      const locationUsersId = data?.items?.[0]?.id;
      const { from, to, count, items } = await fetchShifts({
        startDate: viewModel.data.startDate,
        endDate: viewModel.data.endDate,
      });
      const events = getEvents(items);
      const shiftIds = events.map((e) => e.shiftId);
      setLocationUsersId(locationUsersId);
      if (locationUsersId) {
        const typeRelation = await UWEEntityApi.getTypeRelationId({
          sourceName: "Shift Instance",
          targetName: "Users",
        })
        const filters = {
          sourceEntityId: shiftIds.toString(),
          typeRelationId: typeRelation,
          "metadata-like-name": searchValue,
        };

        const users = await UWEEntityApi.getWorkTray({
          resource: "uwe-entities/Users/list",
          offset,
          size,
          ...filters,
        });
        return users;
      }
      return { count: 0, items: [] };
    } else {
      payload = { ...payload, ...{ "metadata-like-name": searchValue } };
    }

    const data = await UWEEntityApi.getWorkTray(payload);
    return data;
  };

  useEffect(() => {
    const bootstrapAsync = async () => {
      // For Location Shifts dashboard, don't fetch until entityData has loaded.
      if (!isUsersShiftTray && !entityData) return;
      setIsLoadingResources(true);

      const userOffset = (userCurrentPageNumber - 1) * userPageSize; // offset formula
      console.log("userPageSize: ", userPageSize);
      const { count, items } = await fetchUsers(userOffset, userPageSize);

      // Get the scheduler resources
      const resources = getResources(items);
      setResources(resources);

      setUserTotal(count);

      setIsLoadingResources(false);
    };
    bootstrapAsync();
  }, [
    entityData,
    userCurrentPageNumber,
    filterFormValues,
    searchValue,
    userPageSize,
  ]);

  /*
   * =============================
   * = SCHEDULER CONFIGURATION
   * =============================
   */
  // Disable/Enable header based on dashboard type
  const userSchedulerConfig = useMemo(
    () => ({
      ...mainSchedulerConfig,
      tableHeaderHeight: isUsersShiftTray ? 30 : 0,
      nonAgendaSlotMinHeight: isUsersShiftTray ? 50 : 30,
    }),
    [isUsersShiftTray]
  );

  // ------------------------
  // - User Shift Scheduler
  // ------------------------
  // CUSTOM FUNCTION FOR DEFINING RESOURCES COLUMN SLOTS
  const userSlotItemTemplateResolver = (
    schedulerData,
    slot,
    slotClickedFunc,
    width,
    clsName
  ) => {
    const id = slot.slotId;
    const name = slot.slotName;
    const initials = name
      .toUpperCase()
      .split(" ")
      .map((w) => w[0]);
    return (
      <div
        key={id}
        className={clsName}
        role="button"
        tabIndex={0}
        style={{ width, textAlign: "left", paddingLeft: 5 }}
        onClick={() => getUserCalendar(id)}
      >
        <Avatar size={35}>
          <span>{initials}</span>
        </Avatar>
        <span style={{ marginLeft: 10 }}>{name}</span>
      </div>
    );
  };

  // CUSTOM FUNCTION FOR DEFINING EVENT NAMES
  const eventTextFunc = useMemo(() => {
    if (isUsersShiftTray) {
      return userEventTextFunc;
    } else {
      return locationEventTextFunc;
    }
  }, [isUsersShiftTray]);

  // CUSTOM FUNCTION FOR DEFINING EVENT ITEM LOOK
  const eventItemTemplateResolver = useMemo(() => {
    if (isUsersShiftTray) {
      return userEventItemTemplateResolver;
    } else {
      return locationEventItemTemplateResolver;
    }
  }, [isUsersShiftTray]);

  const [viewModel, setViewModel] = useSchedulerData({
    events,
    resources,
    config: userSchedulerConfig,
    behaviors: { eventTextFunc },
  });

  // -----------------------------------------------
  // - Open Shift Scheduler (only for location view)
  // -----------------------------------------------
  // Only has the empty resource, but same events.
  const [openViewModel, setOpenViewModel] = useSchedulerData({
    events,
    resources: null,
    config: openSchedulerConfig,
    behaviors: { eventTextFunc },
  });

  /*
   * ========================
   * = NOTES TABLE AND STATE
   * ========================
   */
  // Reverse engineers the scheduler styling to stay consistent with scheduler table
  const [currenNote, setCurrentNote] = useState(null);
  const [noteValues, setNoteValues] = useState(null);
  const [isDayNote, setIsDayNote] = useState(false);
  const noteCharacterLimit = 100;

  const recalculateNotes = () => {
    // Do not load until notes loaded (defaultValue controlled vs uncontrolled component shenanigans)
    if (!entityData) return;

    const notes = entityData.data.notes || [];

    // Get the dates in range (get start of range, use as base to add i days to it)
    // Keep track of them in array, but also a map with the date in YYYY-MM-DD format as key
    const start = viewModel.data?.getViewStartDate();
    const viewDayCount = isDayNote ? 1 : 7;
    const days = [];
    const isDay = new Set();
    for (let i = 0; i < viewDayCount; i += 1) {
      const newDay = start.add(i, "day");
      days.push(newDay);
      isDay.add(newDay.format("YYYY-MM-DD"));
    }

    // Fetch the notes corresponding to the dates, and set current row of notes
    const notesPerDay = new Map();
    notes.forEach(({ notes, date }) => {
      if (isDay.has(date)) {
        notesPerDay.set(date, notes);
      }
    });

    const values = days.map((d) => {
      const noteKey = d.format("YYYY-MM-DD");
      return notesPerDay.has(noteKey) ? notesPerDay.get(noteKey) : "";
    });

    setNoteValues(values);
  };

  useEffect(() => {
    recalculateNotes();
  }, [entityData, viewModel, isDayNote]);

  /*
   * ======================
   * = SCHEDULER CALLBACKS
   * ======================
   */
  const prevClick = useCallback(
    async (_) => {
      setIsLoadingEvents(true);
      // If already going beyond startDate, do nothing.
      const start = viewModel.data.getViewStartDate();
      if (start.isBefore(dayjs(actualStartDate))) {
        window.alert(`Cannot go to dates before ${actualStartDate}.`);
        return;
      }

      viewModel.data.prev();

      const { items: itm } = await fetchShifts({
        startDate: viewModel.data.startDate,
        endDate: viewModel.data.endDate,
      });

      const events = getEvents(itm);

      viewModel.data.setEvents(events);

      openViewModel.data.prev();
      openViewModel.data.setEvents(events);

      // Update the resource name to reflect current view
      const unassignedResource = getUnassignedResource(
        openViewModel.data,
        events
      );
      openViewModel.data.setResources([unassignedResource]);

      const userOffset = (userCurrentPageNumber - 1) * userPageSize;
      const { count, items } = await fetchUsers(userOffset, userPageSize);
      viewModel.data.setResources(getResources(items));
      setUserTotal(count);

      setViewModel({data: viewModel.data});
      setOpenViewModel({ data: openViewModel.data });
      setIsLoadingEvents(false);
    },
    [viewModel, openViewModel, events]
  );

  const nextClick = useCallback(
    async (_) => {
      setIsLoadingEvents(true);

      // If already going beyond endDate, do nothing.
      const end = viewModel.data.getViewEndDate();
      if (end.isAfter(dayjs(actualEndDate))) {
        window.alert(`Cannot go to dates after ${actualEndDate}.`);
        return;
      }

      viewModel.data.next();

      const { items: itm } = await fetchShifts({
        startDate: viewModel.data.startDate,
        endDate: viewModel.data.endDate,
      });

      // Fetch the shift allocations using cached load HERE!
      // Get the scheduler events
      const events = getEvents(itm);

      viewModel.data.setEvents(events);

      openViewModel.data.next();
      openViewModel.data.setEvents(events);

      // Update the resource name to reflect current view
      const unassignedResource = getUnassignedResource(
        openViewModel.data,
        events
      );
      openViewModel.data.setResources([unassignedResource]);

      const userOffset = (userCurrentPageNumber - 1) * userPageSize;
      const { count, items } = await fetchUsers(userOffset, userPageSize);
      viewModel.data.setResources(getResources(items));
      setUserTotal(count);
      
      setViewModel({ data: viewModel.data });
      setOpenViewModel({ data: openViewModel.data });
      setIsLoadingEvents(false);
    },
    [viewModel, openViewModel, events]
  );

  const onViewChange = useCallback(
    async (_, view) => {
      setIsLoadingEvents(true);
      viewModel.data.setViewType(
        view.viewType,
        view.showAgenda,
        view.isEventPerspective
      );

      const { items: itm } = await fetchShifts({
        startDate: viewModel.data.startDate,
        endDate: viewModel.data.endDate,
      });
      const events = getEvents(itm);
      viewModel.data.setEvents(events);

      openViewModel.data.setViewType(
        view.viewType,
        view.showAgenda,
        view.isEventPerspective
      );
      openViewModel.data.setEvents(events);

      // Update the resource name to reflect current view
      const unassignedResource = getUnassignedResource(
        openViewModel.data,
        events
      );
      openViewModel.data.setResources([unassignedResource]);

      // const userOffset = (userCurrentPageNumber - 1) * userPageSize;
      // const { count, items } = await fetchUsers(userOffset, userPageSize);
      // viewModel.data.setResources(getResources(items));
      // setUserTotal(count);

      setIsDayNote(view.viewType === ViewType.Day);

      // Pass true as second argument to indicate this is from a view change
      setViewModel({ data: viewModel.data }, true);
      setOpenViewModel({ data: openViewModel.data });
      setIsLoadingEvents(false);
    },
    [viewModel, openViewModel, events]
  );

  const eventItemClick = (schedulerData, event) => {
    window.open(`/workflow/shift-instance/${event.shiftId}`, "_blank");
  };

  const onSelectDate = (e) => {
    
  };

  const newEvent = (
    schedulerData,
    slotId,
    slotName,
    start,
    end,
    type,
    item
  ) => {};

  const onPaginationChange = async (page, pageSize) => {
    if(!isUsersShiftTray) {
      setIsLoadingEvents(true);

      const { items: itm } = await fetchShifts({
        startDate: viewModel.data.startDate,
        endDate: viewModel.data.endDate,
      });

      // Fetch the shift allocations using cached load HERE!
      // Get the scheduler events
      const events = getEvents(itm);

      viewModel.data.setEvents(events);
      // Instead of using setDate(), store and reuse the current view state
      const currentViewType = viewModel.data.viewType;
      const currentStartDate = viewModel.data.startDate;
      
      // Update the view preserving the dates
      viewModel.data.setViewType(
        currentViewType,
        false, // showAgenda
        false  // isEventPerspective
      );
      viewModel.data.date = currentStartDate; // Use it to maintain the date
      openViewModel.data.setViewType(
        currentViewType,
        false,
        false
      );
      openViewModel.data.date = currentStartDate; // Keep both schedulers in sync

      openViewModel.data.setEvents(events);

      // Update the resource name to reflect current view
      const unassignedResource = getUnassignedResource(
        openViewModel.data,
        events
      );
      openViewModel.data.setResources([unassignedResource]);

      const userOffset = (page - 1) * pageSize;
      const { count, items } = await fetchUsers(userOffset, pageSize);
      viewModel.data.setResources(getResources(items));
      setUserTotal(count);

      setViewModel({ data: viewModel.data });
      setOpenViewModel({ data: openViewModel.data });
      setIsLoadingEvents(false);
      onViewChange(null, viewModel.data);
    }
    setUserCurrentPageNumber(page);
    setUserPageSize(pageSize);
  };

  const onSearch = (value, _e, info) => setSearchValue(value);

  const getUserCalendar = (id) => {
    history.push(`/workflow/users/${id}/view`);
  };

  /*
   * ==============================
   * = WORKAROUNDS FOR MISC ISSUES
   * ==============================
   */
  // - Scheduler: Workaround for first scheduler not resizing automatically:
  // The first scheduler's data has a property .documentWidth that doesnt update. We do it manually
  const handleResize = useCallback(() => {
    const schedulerData = viewModel.data;
    const openSchedulerData = openViewModel.data;
    if (!schedulerData || !openSchedulerData) return;

    openSchedulerData.documentWidth = schedulerData.documentWidth;
    setOpenViewModel({ data: openSchedulerData });
  }, [viewModel, openViewModel]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [viewModel, openViewModel]);

  // - Notes
  // To resize notes table dynamically with the scheduler
  const tableRef = useRef(null);

  const besidesWidth = mainSchedulerConfig.besidesWidth || 20;
  const schedulerWidth =
    parseInt(mainSchedulerConfig.schedulerWidth || "93%", 10) / 100;
  const handleResizeForTable = () => {
    if (!tableRef.current) return;
    tableRef.current.width =
      (window.innerWidth - besidesWidth) * schedulerWidth;
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeForTable);
    return () => {
      window.removeEventListener("resize", handleResizeForTable);
    };
  }, []);

  // Resize notes table on view change
  useEffect(() => {
    handleResizeForTable();
  }, [isDayNote]);

  const onSearchChange = debounce((v) => onSearch(v), 250, { trailing: true });

  // useEffect(() => {
  //   console.log("EVENTS: ", events.filter(e => e.resourceId !== UNASSIGNED_RESOURCE_ID))
  //   console.log("VIEWMODEL EVENTS: ", viewModel?.data?.events?.filter(e => e.resourceId !== UNASSIGNED_RESOURCE_ID))
  // }, [events, viewModel])
  /*
   * =================
   * = RENDERING
   * =================
   */
  const renderHeader = () => (
    <Row
      style={{
        borderStyle: "solid",
        borderColor: "#D3D3D3",
        borderWidth: "0px 0px 1px 0px",
        paddingBottom: 6,
      }}
    >
      <Col span={7}>
        <Flex justify="flex-start" align="flex-start" gap="large">
          <div>
            <h1 style={{ fontSize: 22, marginBottom: 0 }}>
              {entityData?.data?.name || "Loading Location..."}
            </h1>
            {entityData?.id && (
              <span style={{ fontSize: 14, marginLeft: 2 }}>
                L{leftPad(entityData?.id, 7)}
              </span>
            )}
          </div>
          <span style={{ fontSize: 18, paddingTop: 1, left: 0 }}>
            Status: {entityData && (isActive ? "Active" : "Inactive")}
          </span>
          {(!entityData || isLoadingEvents) && (
            <Spinner size="sm" style={{ marginTop: 8, color:"#FF9A0E" }} />
          )}
        </Flex>
      </Col>
      <Col span={10}>
        <Flex justify="center">
          <Nav justified>
            <NavItem>
              <NavLink active href="#">
                <u>
                  <h2 style={{ fontSize: 21 }}>Schedule</h2>
                </u>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                disabled={!entityData || !id}
                onClick={() => history.push(`/workflow/location/${id}`)}
              >
                <h2 style={{ fontSize: 21 }}>Settings</h2>
              </NavLink>
            </NavItem>
            <NavItem>
              {locationUsersId && (
                <NavLink
                  disabled={!entityData || !id}
                  onClick={() =>
                    history.push(
                      `/workflow/location-users/${locationUsersId}?team=true`
                    )
                  }
                >
                  <h2 style={{ fontSize: 21 }}>Team</h2>
                </NavLink>
              )}
            </NavItem>
          </Nav>
        </Flex>
      </Col>

      <Col span={7}>
        <Flex justify="flex-end" align="center">
          <Button
            type="primary"
            style={{
              backgroundColor: "#FF9A0E",
              borderColor: "#FF9A0E",
              color: "white",
            }}
            className="btn btn-primary worktray-action-btn"
            disabled={!entityData || !id}
            onClick={() => {
              const {
                jobType,
                address: { address },
                availableSlots,
                jobDuration,
              } = entityData.data;

              const query = {
                location: id,
                jobType: jobType.id,
                address: address || "",
                availableSlots: availableSlots || 0,
                jobDuration: jobDuration || 0,
              };
              history.push(`/workflow/job/new?${queryString.stringify(query)}`);
            }}
          >
            New Shift <i className="fa fa-plus" />
          </Button>
        </Flex>
      </Col>
    </Row>
  );

  const renderNotesTable = () => {
    // Do not load until notes loaded (defaultValue controlled vs uncontrolled component shenanigans)
    if (!entityData || !noteValues || !viewModel.data) return;

    const thElement = document.querySelector("table.scheduler");
    const widthThElement = thElement?.clientWidth;

    const start = viewModel.data.getViewStartDate();
    const days = [];
    const viewDayCount = isDayNote ? 1 : 7;
    for (let i = 0; i < viewDayCount; i += 1) {
      const newDay = dayjs(start).add(i, "day");
      days.push(newDay);
    }

    const cellWidth = isDayNote ? "100%" : "12%";
    const cells = [];
    for (let i = 0; i < viewDayCount; i += 1) {
      cells.push(
        <td
          key={i}
          style={{
            width: cellWidth,
            verticalAlign: "top",
          }}
        >
          <TextArea
            placeholder={days[i].format("ddd MM/DD")}
            onFocus={() => {
              setCurrentNote(i);
            }}
            onBlur={() => {
              // Add Saving code here!!!

              // To avoid data race when focusing on other text area:
              // 1) This is invoked first => currentNote is i, set to null. Overwritten by next onFocus.
              // 2) This is invoked second => Check if currentNote is i. If not, do nothing. Else, make null.
              setCurrentNote((prev) => (prev === i ? null : prev));
            }}
            autoSize={currenNote === i}
            defaultValue={noteValues[i]}
            value={noteValues[i]}
            style={{
              height: "12px",
              resize: "none",
              background: "none",
              margin: "0 auto",
              display: "block",
            }}
            maxLength={noteCharacterLimit + 1}
            count={{
              max: noteCharacterLimit,
            }}
            onChange={(e) => {
              setNoteValues((prev) => {
                const cur = [...prev];
                cur[i] = e.target.value;
                return cur;
              });
            }}
          />
        </td>
      );
    }

    return (
      <table
        ref={tableRef}
        className="table-bordered table-striped scheduler"
        style={{
          width: `${widthThElement}px`,
          marginTop: "0px",
        }}
      >
        <tbody>
          <tr>
            <th scope="row">Day Notes</th>
            {cells}
          </tr>
        </tbody>
      </table>
    );
  };

  const renderUserShifts = () => (
    <div>
      <div className="worktray-header">
        <div className={`title ${element.tableActions ? "has-buttons" : ""}`}>
          <h2>Shift Schedule</h2>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 8,
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        <small style={{ color: "gray", fontSize: "10pt" }}>
          <Trans>Filters:</Trans>
        </small>
        <FilterForm
          element={element}
          onChange={(formData) => setFilterFormValues(formData)}
        />
      </div>

      <div className="row">
        <div className="col-2">
          <SearchBar
            className="scheduler-search-bar"
            onChange={onSearchChange}
            placeholder={t`Search by user name...`}
          />
        </div>
        {isLoadingEvents && (
          <Spinner size="lg" style={{ marginLeft: 8, marginBottom: 2, color:"#FF9A0E" }} />
        )}
      </div>

      <div
        className="d-flex flex-column align-items-center"
        style={{
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        {viewModel.data && !isLoadingResources ? (
          <Scheduler
            schedulerData={viewModel.data}
            prevClick={prevClick}
            nextClick={nextClick}
            onViewChange={onViewChange}
            eventItemClick={eventItemClick}
            onSelectDate={onSelectDate}
            newEvent={newEvent}
            eventItemTemplateResolver={eventItemTemplateResolver}
            eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
            slotItemTemplateResolver={userSlotItemTemplateResolver}
          />
        ) : (
          <Spinner size="lg" style={{ marginTop: 8, marginBottom: 8, color:"#FF9A0E" }} />
        )}
        <Pagination
          style={{
            display: "inline-block",
          }}
          defaultCurrent={1}
          total={userTotal}
          pageSize={userPageSize}
          current={userCurrentPageNumber}
          onChange={onPaginationChange}
        />
      </div>
    </div>
  );

  const renderLocationShifts = () => {
    const tableElement = document.querySelector("table.scheduler");
    const thElement = document.querySelector("th.header3-text");
    const widthThElement = thElement?.clientWidth;
    const widthTableElement = tableElement?.clientWidth;
    
    return (
      <div>
        {renderHeader()}
        <div
          style={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {entityData && openViewModel.data && !isLoadingResources ? (
            <Flex justify="center" vertical>
              <Scheduler
                schedulerData={openViewModel.data}
                prevClick={prevClick}
                nextClick={nextClick}
                onViewChange={onViewChange}
                eventItemClick={eventItemClick}
                onSelectDate={onSelectDate}
                eventItemTemplateResolver={eventItemTemplateResolver}
                eventItemPopoverTemplateResolver={
                  eventItemPopoverTemplateResolver
                }
              />
              {renderNotesTable()}
            </Flex>
          ) : (
            <Spinner size="lg" style={{ marginTop: 8, marginBottom: 8, color:"#FF9A0E" }} />
          )}
        </div>
        {entityData && viewModel.data && !isLoadingResources ? (
          <div
            style={{
              width: "100%",
              textAlign: "left",
              justifyContent: "left",
            }}
          >
            <Flex justify="left" vertical>
              <div
                style={{
                  width: `${widthTableElement}px`,
                  marginTop: 0,
                  marginBottom: 0,
                }}
                className="scheduler"
              >
                <Search
                  placeholder="Search users"
                  onSearch={onSearch}
                  allowClear
                  style={{
                    display: "inline-block",
                    position: "relative",
                    width: `${widthThElement}px`,
                  }}
                />
              </div>
            </Flex>
          </div>
        ) : null}
        <div
          className="d-flex flex-column align-items-center"
          style={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
          }}
        >        
          {entityData && viewModel.data && !isLoadingResources ? (
            isLoadingEvents ? (
              <Spinner size="lg" style={{ marginTop: 8, marginBottom: 8, color:"#FF9A0E" }} />
            ) : (
              <>
                <Scheduler
                  schedulerData={viewModel.data}
                  prevClick={prevClick}
                  nextClick={nextClick}
                  onViewChange={onViewChange}
                  eventItemClick={eventItemClick}
                  onSelectDate={onSelectDate}
                  newEvent={newEvent}
                  eventItemTemplateResolver={eventItemTemplateResolver}
                  eventItemPopoverTemplateResolver={
                    eventItemPopoverTemplateResolver
                  }
                />
                <Pagination
                  style={{
                    display: "inline-block",
                  }}
                  defaultCurrent={1}
                  total={userTotal}
                  pageSize={userPageSize}
                  current={userCurrentPageNumber}
                  onChange={onPaginationChange}
                  showSizeChanger
                />
              </>
            )
          ) : null}
          
        </div>
      </div>
    );
  };

  const renderShifts = () => {
    return isUsersShiftTray ? renderUserShifts() : renderLocationShifts();
  };

  return (
    <Flex vertical justify="flex-start" className="worktray">
      {renderShifts()}
    </Flex>
  );
}

const ShiftSchedulerDnD = wrapperFun(ShiftScheduler);

ShiftSchedulerDnD.rootSchema = {
  definitions: {
    mapFieldField: {
      type: ["string", "object"],
      "ui:field": "JsonField",
      showTree: true,
    },
  },
  properties: {
    entityType: {
      title: "Entity Type",
      type: "string",
      "map:field": "entityType",
      "ui:field": "LookupFormField",
      lookup: {
        resource: "Entity Types",
        api: "api:manage/objectschemas",
        params: { all: true },
        options: { useCache: true },
        jnx: "data.items",
        id: "name",
        label: "name",
      },
    },
    startDate: {
      title: "Start Date",
      type: "string",
      format: "date",
      structure: "Text",
      "map:field": "startDate",
    },
    endDate: {
      title: "End Date",
      type: "string",
      format: "date",
      structure: "Text",
      "map:field": "endDate",
    },
    ...filterFormPropertySchema,
  },
};

// ----------------------------------
// - Scheduler init as Hooks/Helpers
// ----------------------------------

// Functions for getting event text
const userEventTextFunc = (schedulerData, event) => {
  const year = dayjs(event.start).format("YY");
  const shiftCardId = `${year}-${leftPad(event.shiftId, 7)}`;
  return shiftCardId;
};

const locationEventTextFunc = (schedulerData, event) => {
  const startDate = dayjs(event.start);
  const endDate = dayjs(event.end);
  return `${startDate.format("ha")} - ${endDate.format("ha")}`;
};

const eventItemPopoverTemplateResolver = (
  schedulerData,
  eventItem,
  title,
  start,
  end,
  statusColor
) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
        }}
      >
        <span
          style={{
            height: "12px",
            width: "12px",
            backgroundColor: statusColor,
            borderRadius: "50%",
            display: "inline-block",
            marginTop: "5.25px",
            marginRight: "4px",
          }}
        />
        <b
          style={{
            display: "inline-block",
            fontSize: 14,
          }}
        >
          {eventItem.title}
        </b>
      </div>
      <span
        style={{
          fontSize: 16,
        }}
      >
        {start.format("M/D h:mmA")} - {end.format("h:mmA")}
      </span>
    </div>
  );
};

// Template resolvers: CUSTOM FUNCTIONS FOR DEFINING EVENT LOOK
const locationEventItemTemplateResolver = (
  schedulerData,
  event,
  bgColor,
  isStart,
  isEnd,
  mustAddCssClass,
  mustBeHeight,
  agendaMaxEventWidth
) => {
  const titleText = schedulerData.behaviors.getEventTextFunc(
    schedulerData,
    event
  );
  const backgroundColor = Color.getBlendedColor(bgColor, "white");
  const hasSlots =
    (event.shiftClosedSlots === 0 || event.shiftClosedSlots) &&
    (event.shiftInitialSlots === 0 || event.shiftInitialSlots);
  return (
    <div
      key={event.id}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: mustBeHeight,
        border: `1.5px solid ${bgColor}`,
        backgroundColor,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: "center",

        overflowX: "hidden",
        textOverflow: "clip",
        whiteSpace: "nowrap",
      }}
    >
      <span
        style={{
          display: "block",
          lineHeight: `${mustBeHeight}px`,
          color: "black",
          fontSize: 14,
          minWidth: 90,
        }}
      >
        {titleText}
      </span>
      {hasSlots && (
        <span
          style={{
            display: "block",
            border: `1.5px solid black`,
            backgroundColor: "white",
            height: mustBeHeight - 3,
            minWidth: 30,
            color: "black",
            fontSize: 11,
            paddingLeft: 4,
            paddingRight: 4,
            borderRadius: 10,
            textAlign: "center",
          }}
        >
          {event.shiftClosedSlots}/{event.shiftInitialSlots}
        </span>
      )}
    </div>
  );
};

const userEventItemTemplateResolver = (
  schedulerData,
  event,
  bgColor,
  isStart,
  isEnd,
  mustAddCssClass,
  mustBeHeight,
  agendaMaxEventWidth
) => {
  const titleText = schedulerData.behaviors.getEventTextFunc(
    schedulerData,
    event
  );

  // To simulate swimlane:
  // Calculate marginLeft and width to match the percentage of day the shift takes up via start and end times.
  const { start, end, shiftDate } = event;

  const day = dayjs(shiftDate);
  const startOfDay = day.startOf("day");

  const marginLeft =
    (dayjs(start).diff(startOfDay, "minute", false) * 100) / 1440;
  const width =
    (dayjs(end).diff(startOfDay, "minute", false) * 100) / 1440 - marginLeft;

  const backgroundColor = Color.getBlendedColor(bgColor, "white");

  return (
    <div
      key={event.id}
      className="d-flex flex-column align-items-center"
      style={{
        height: mustBeHeight - 2,
        border: `1.5px solid ${bgColor}`,
        backgroundColor,
        borderRadius: 5,
        color: "black",
        fontSize: 12,
      }}
    >
      {titleText}
      <div
        key={`${event.id}-line`}
        style={{
          backgroundColor: "transparent",
          border: `1px solid ${bgColor}`,
          width: `${width}%`,
          marginLeft: `${marginLeft}%`,
          paddingRight: "0px !important",
        }}
      />
    </div>
  );
};

// This allows creating multiple scheduler outside of main component logic, thus each reactive!
const useSchedulerData = (props) => {
  const {
    events,
    resources,
    config,
    behaviors: { eventTextFunc },
  } = props;
  // The schedulerData used for rendering... initialized to the default one.
  const [viewModel, setViewModel] = useState({ data: null });
  const isViewChangeRef = useRef(false);

  // Recreates a scheduler config when events or resources arrays change.
  useEffect(() => {
    // Skip effect if update is from view change
    if (isViewChangeRef.current) {
      isViewChangeRef.current = false;
      return;
    }
    // Initializes the container for all users' shift data.
    // Modify this object with its methods to alter the scheduler's contents.
    const schedulerData = new SchedulerData(
      dayjs().format(DATE_FORMAT),
      ViewType.Week,
      false, // showAgenda?
      false, // isEventPerspective?
      config
    );
    // Set locale dayjs to the schedulerData, if your locale isn't English. By default, Scheduler comes with English(en, United States).
    schedulerData.setSchedulerLocale(dayjsLocale); // this uses dayjs, but it doesn't require dayjs to be installed as its called dynamically
    schedulerData.setCalendarPopoverLocale(antdLocale); // this uses antd [List of supported locales](https://ant.design/docs/react/i18n#supported-languages)
    schedulerData.localeDayjs.locale("es-pr");
    // Set the resources used to group together tasks.
    // Has a tree structure: each node has an id, a name, and a parentId, as well as a grouOnly flag
    if (resources) {
      schedulerData.setResources(resources);
    } else {
      // If no resources are given, create unassignedResource
      const unassignedResource = getUnassignedResource(schedulerData, events);
      schedulerData.setResources([unassignedResource]);
    }

    // The event array should be sorted in ascending order by event.start property.
    // Otherwise there will be some rendering errors
    schedulerData.setEvents(events);

    // Set the width manually, to avoid bug where it isnt set...
    schedulerData.documentWidth = window.innerWidth;

    // Display hours on event
    if (eventTextFunc) {
      schedulerData.behaviors.getEventTextFunc = eventTextFunc;
    }

    setViewModel({ data: schedulerData });
  }, [events, resources]);

  // Custom setter that allows flagging view changes
  const customSetViewModel = (newModel, isViewChange = false) => {
    isViewChangeRef.current = isViewChange;
    setViewModel(newModel);
  };

  return [viewModel, customSetViewModel];
};

const getUnassignedResource = (schedulerData, events) => {
  return {
    id: UNASSIGNED_RESOURCE_ID,
    name: getUnassignedResourceName(schedulerData, events),
  };
};

const getUnassignedResourceName = (schedulerData, events) => {
  // Filter events further by the current view
  const viewStartDate = schedulerData.getViewStartDate();
  const viewEndDate = schedulerData.getViewEndDate();

  const now = dayjs();

  // Count number of unassigned events in view range
  const allEvents = events || [];
  let countUnassigned = 0;
  for (let i = 0; i < allEvents.length; i += 1) {
    const e = allEvents[i];

    const startDate = dayjs(e.start);
    const endDate = dayjs(e.end);
    if (
      e.shiftOpenSlots !== null &&
      e.resourceId === UNASSIGNED_RESOURCE_ID &&
      startDate.isSameOrAfter(viewStartDate) &&
      endDate.isBefore(viewEndDate) && // non-inclusive
      !(startDate.isBefore(now) && endDate.isBefore(now)) // Do not count if range is completely before now (same logic as active/inactive minus overlap)
    ) {
      countUnassigned += e.shiftOpenSlots;
    }
  }
  // console.log("START: ", viewStartDate.format("YYYY-MM-DD"))
  // console.log("END: ", viewEndDate.format("YYYY-MM-DD"))
  // console.log("DATES BY DATE: ", DEBUG.reduce((acc, e) => ({...acc, [e.shiftDate]: [...(acc[e.shiftDate] || []), e]}), {}))
  if (countUnassigned > 0) {
    return `${countUnassigned} slots`;
  } else {
    return "No Shifts Scheduled";
  }
};

// -----------------
// - Convert Shifts
// -----------------
// Derive events from shifts
/*
  Events:
  {
    id: 1,
    start: '2024-06-11 09:30:00',
    end: '2024-06-11 23:30:00',
    resourceId: '1',
    title: 'I am finished',
    bgColor: '#D9D9D9',
  }
*/
const getEvents = (shiftItems) => {
  let counter = 0;

  // Retrieve the events + extract resource ids
  const shiftEvents = [];
  for (let i = 0; i < shiftItems.length; i += 1) {
    const shift = shiftItems[i];
    const {
      name,
      // number,
      labelColor,
      startTime,
      endTime,
      shiftDate,
      shiftAllocations,
      shiftAllocationsUserIds,
      availableSlots,
    } = shift.metadata;
    const id = shift.id;

    // Skip shift if it doesn't have necessary information
    if (!startTime || !endTime) {
      continue; // eslint-disable-line no-continue
    }

    // Get the shift's values with defaults
    const shiftTitle = name || counter.toString();
    const shiftColor = labelColor || "Gray";
    const shiftStart = `${shiftDate} ${startTime}`;
    const shiftEnd = `${shiftDate} ${endTime}`;

    // Obtain all shift users
    const shiftInitialSlots = Math.max(parseInt(availableSlots, 10) || 0, 0);
    const shiftUsers = [];
    if (shiftAllocationsUserIds) {
      const userIds = JSON.parse(shiftAllocationsUserIds);
      for (let j = 0; j < userIds.length; j += 1) {
        shiftUsers.push(userIds[j]);
      }
    } else if (shiftAllocations) {
      /**
       * The original way of parsing the users from raw shiftAllocation string.
       * Has a lot of legacy cruft. Remove eventually when data is cleaned.
       * @deprecated
       */
      const allocations = JSON.parse(shiftAllocations);

      // Get users for a shift by finding all users whose allocation status is "Confirmed" or "Completed"
      // NOTE: Possible to keep track of this value to change look of event.
      for (let j = 0; j < allocations.length; j += 1) {
        if (allocations[j]?.status) {
          let userId = parseInt(allocations[j]?.userId, 10);
          if (Number.isNaN(userId)) {
            userId = shiftUsers[j]?.id || UNASSIGNED_RESOURCE_ID; // It is part of the data which is an object, and if THAT fails, unassigned.
          }
          shiftUsers.push(userId);
        }
      }
    }

    // Get the number of closed slots.
    const shiftClosedSlots = shiftUsers.length;

    // For each user, create a scheduler event for given shift data, and keep track of it.
    for (let j = 0; j < shiftUsers.length; j += 1) {
      const userId = shiftUsers[j];

      shiftEvents.push({
        id: counter,
        bgColor: shiftColor,
        title: shiftTitle,
        resourceId: userId,
        start: shiftStart,
        end: shiftEnd,

        // Custom properties not used by react-big-scheduler
        shiftId: id,
        shiftDate,
        shiftOpenSlots: null,
        shiftInitialSlots,
        shiftClosedSlots,
      });
      counter += 1;
    }

    // If there exist any open slots, create an unassigned event with the number of open slots in its data.
    const shiftOpenSlots = Math.max(shiftInitialSlots - shiftClosedSlots, 0);
    if (shiftOpenSlots > 0)
      // If there are no shiftUsers, this shift is unassigned... Give it the empty resource.
      shiftEvents.push({
        id: counter,
        bgColor: shiftColor,
        title: shiftTitle,
        resourceId: UNASSIGNED_RESOURCE_ID,
        start: shiftStart,
        end: shiftEnd,

        // Custom properties not used by react-big-scheduler
        shiftId: id,
        shiftDate,
        shiftOpenSlots,
        shiftInitialSlots,
        shiftClosedSlots,
      });
    counter += 1;
  }

  // Return the scheduler events
  return shiftEvents;
};

/*
  Resources:
  { id: 0, name: 'Nombre Real' }

  IMPORTANT IMPLEMENTATION DETAIL: THE RESOURCE ID IS THE SAME AS UWE USER ENTITY ID
*/
const getResources = (users) => {
  const resources = [];
  for (let i = 0; i < users.length; i += 1) {
    resources.push({
      id: users[i].id,
      name:
        users[i]?.metadata?.name || users[i]?.name || users[i].id?.toString(),
    });
  }
  return resources;
};

export default ShiftSchedulerDnD;
