import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _ from 'lodash';
import { useEffect, useMemo } from "react";
import { useJnx } from "../../util/jnx";
import getPathFromId from '../../util/getPathFromId';
import { useSideChannelSubscription } from "../../util/useSideChannel";

const getLocalTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export default function MuiDateTimePicker({ 
    formData: value, 
    onChange, 
    uiSchema,
    readonly,
    idSchema: { $id } = {},
    formContext: {
        setFormDataValues,
        sideChannel
    },
}) {
    const {
        "ui:minutesStep": minutesStep,
        "ui:timeSteps": timeSteps,
        "ui:minDate": minDateOptions,
        'ui:onSelect': onSelectExpr,
        "ui:minTime": minTimeOption,
    } = uiSchema;

    const localTimeZone = useMemo(() => getLocalTimeZone(), []);

    const functionBinds = useMemo(() => ({ set: setFormDataValues }), [setFormDataValues]);
    const onSelectJnx = useJnx(onSelectExpr, { functionBinds });
    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const path = useMemo(() => getPathFromId($id), [$id]);

    useEffect(() => {
        if (onSelectJnx && rootFormData && path && value) {
            const timeoutId = setTimeout(() => {
                onSelectJnx.eval(rootFormData, '', { fieldPath: path, object: value });
            }, 500);

            return () => clearTimeout(timeoutId); 
        }
    }, [onSelectJnx, path, value]);

    let startEndTime = rootFormData?.editShiftInstance?.header?.StartEndTimes;
    let minTimeValue = 0;
    if (startEndTime && minTimeOption){
        startEndTime = startEndTime.split(':')
        if (startEndTime.length >= 1){
            startEndTime = startEndTime[0]
            minTimeValue = parseInt(startEndTime, 10);
        }
    }
    
    const minDate = minDateOptions === "current" ? dayjs() : null;
    const minTime = dayjs().set('hour', minTimeValue).startOf('hour');
    
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                value={value ? dayjs(value) : null}
                onChange={newValue => onChange(newValue.toISOString())}
                minutesStep={minutesStep}
                timeSteps={timeSteps}
                timezone={localTimeZone}
                minDate={minDate}
                readOnly={readonly}
                minTime={minTime}
            />
        </LocalizationProvider>
    );
}