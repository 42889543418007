import SendEmail from "./SendEmail";
import FileField from "./FileField";
import Button from "./Button";
import UWEEntityZipDownloadField from "./UWEEntityZipDownloadField";
import BoolYesNoField from "./BoolYesNoField";
import IconCheckField from "./IconCheckField";
import CheckboxToggleButtonField from "./CheckboxToggleButtonField";
import HideObjectField from "./HideObjectField";
import RiskMatrixField from "./RiskMatrixField";
import RiskMatrixFieldBusiness from "./RiskMatrixFieldBusiness";
import SignatureField from "./SignatureField";
import CompositeInputField from "./CompositeInputField";
import ComputedField from "./ComputedField";
import LookupFormField from "./LookupFormField";
import LookupFormStaticField from "./LookupFormStaticField";
import LookupFilterField from "./LookupFilterField";
import DropdownFilterField from "./DropdownFilterField";
import LookupMultiSelectCheckboxesFormField from "./LookupMultiSelectCheckboxesFormField";
import TextInput from "./TextInput";
import PhoneInput from "./PhoneInput";
import TimerField from "./TimerField";
import SubformField from "./SubformField";
import MoneyInput from "./MoneyInput";
import CedulaInput from "./CedulaInput";
import StaticField from "./StaticField";
import SwitchField from "./SwitchField";
import ToggleObjectField from "./ToggleObjectField";
import ArrayField from "./ArrayField";
import Autocomplete from "./AutocompleteField";
import StaticTextBlurb from "./StaticTextBlurb";
import InteractiveDrawing from "./InteractiveDrawing";
import JnxField from "./JnxField";
import JsonField from "./JsonField";
import JsonSchemaField from "./JsonSchemaField";
import SelectImageField from "./SelectImageField";
import QRCode from "./QRCode";
import QrRead from "./QrRead";
import WorktrayEntitySelectField from "./WorktrayEntitySelectField";
import StaticIFrame from "./StaticIFrame";
import TimeInputField from "./TimeInputField";
import DurationInput from "./DurationInput";
import Counter from "./Counter";
import MuiDateTimePicker from "./MuiDateTimePicker";
import MuiDatePicker from "./MuiDatePicker";
import ColorPicker from "./ColorPicker";
import StringFilterField from "./StringFilterField";
import CheckboxFilterField from "./CheckboxFilterField";
import CalendarView from "./CalendarView";
import AddressMapWidget from "./MapInput";
import DateFilterField from "./DateFilterField";
import LookupMultiSelectFilterField from "./LookupMultiSelectFilterField";

/**
 * The props passed in to each custom field
 *
 * @typedef {Object} WidgetProps
 * @property {string} id - The generated id for this widget, used to provide unique `name`s and `id`s for the HTML field elements rendered by widgets
 * @property {string} name - The unique name of the field, usually derived from the name of the property in the JSONSchema; Provided in support of custom widgets.
 * @property {Object} schema - The JSONSchema subschema object for this widget
 * @property {Object} [uiSchema] - The uiSchema for this widget, where each key is prefixed with "ui:"
 * @property {?} value - The current value for this widget
 *
 * @property {boolean} [required] - The required status of this widget
 * @property {boolean} [disabled] - A boolean value stating if the widget is disabled
 * @property {boolean} [readonly] - A boolean value stating if the widget is read-only
 * @property {boolean} [hideError] - A boolean value stating if the widget is hiding its errors
 * @property {boolean} [autofocus] -  A boolean value stating if the widget should autofocus
 * @property {string} [placeholder] - The placeholder for the widget, if any
 *
 * @property {Object} [formContext] - The `formContext` object that you passed to `Form`
 *
 * @property {function(id: string, value: ?): void} onBlur - The input blur event handler; call it with the widget id and value
 * @property {function(value: ?): void} onChange -  The value change event handler; call it with the new value every time it changes
 * @property {function(id: string, value: ?): void} onFocus - The input focus event handler; call it with the widget id and value
 *
 * @property {string} label - The computed label for this widget, as a string
 * @property {boolean} [hideLabel] - A boolean value, if true, will cause the label to be hidden. This is useful for nested fields where you don't want to clutter the UI. Customized via `label` in the `UiSchema`
 * @property {boolean} [multiple] - A boolean value stating if the widget can accept multiple values
 * @property {Array<string>} [rawErrors] - An array of strings listing all generated error messages from encountered errors for this widget
 * @property {Object} [registry] - The `registry` object
 *
 * @todo Link to the formContext type when it is defined
 * @todo Define schema object for UWE
 * @todo Define uiSchema object for UWE
 * @todo Define callback types for the callbacks, so they show up in generated docs
 *
 * @see {@link https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/utils/src/types.ts| WidgetProps TypeScript type definition}
 * @see {@link https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-widgets-fields|Custom widgets}
 */

/**
 *
 * This module exports custom fields that can be used in UWE forms.
 *
 * Each of these fields is a React component, which takes in certain props which allow
 * developing UWE forms. These props are the {@link https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-widgets-fields|WidgetProps} defined in react-jsonschema-form.
 *
 * @module components/customFields
 * @see {@link https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-widgets-fields|Custom widgets}
 * @todo Standardize the ways these fields are written, by using a common suite of utilities/abstractions
 */

/**
 *
 * This object is passed to the `fields` prop of the {@link https://rjsf-team.github.io/react-jsonschema-form/docs/api-reference/form-props#fields|react-jsonschema-form Form component}.
 *
 * It maps the custom field name to the component which implements it.
 * By the "ui:field" property of the JSON subschema as a key, the Form component chooses which custom component to render
 * by looking up the component using this object.
 *
 * @constant
 * @type {Object<string, function(props: WidgetProps): JSX.Element>}
 */
const customFields = {
	SendEmail,
	FileField,
	Button,
	UWEEntityZipDownloadField,
	RiskMatrixField,
	RiskMatrixFieldBusiness,
	ComputedField,
	SignatureField,
	CompositeInputField,
	HideObjectField,
	BoolYesNoField,
	BoolYesNo: BoolYesNoField,
	IconCheckField,
	CheckboxToggleButtonField,
	LookupFormField,
	LookupFormStaticField,
	LookupMultiSelectCheckboxesFormField,
	TextInput,
	PhoneInput,
	TimerField,
	JnxField,
	JsonField,
	JsonSchemaField,
	SelectImageField,
	MoneyInput,
	SubformField,
	CedulaInput,
	StaticField,
	ArrayField,
	SwitchField,
	ToggleObjectField,
	WorktrayEntitySelectField,
	Autocomplete,
	StaticTextBlurb,
	InteractiveDrawing,
	QRCode,
	QrRead,
	StaticIFrame,
	TimeInputField,
	DurationInput,
	Counter,
	MuiDateTimePicker,
	MuiDatePicker,
	ColorPicker,
	CalendarView,
	AddressMapWidget
};

/**
 * These are the fields used by the `FilterForm` component.
 * This form uses its own set of fields, which may or may not be
 * aliases to the usual form custom fields, used specifically for
 * filtering in dashboards.
 *
 * This is a product of the original solution for filters in the WorkTray dashboard:
 * Using a form embedded in the dashboard to specify filter dropdowns/inputs.
 *
 * @todo Link to docs for module where `FilterForm` is located.
 */
const filterFields = {
	LookupFormField: LookupFilterField,
	StringField: StringFilterField,
	BooleanField: CheckboxFilterField,
	DropdownField: DropdownFilterField,
	DateField: DateFilterField,
	MultiSelectField: LookupMultiSelectFilterField
};

export { filterFields };
export default customFields;
