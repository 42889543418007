import React, { useMemo, useEffect, useState, useCallback } from "react";
import { t } from "@lingui/macro"
import {
    FormGroup,
    Input,
    InputGroup,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    InputGroupText,
    DropdownToggle,
    Label
 } from "reactstrap";

import { Checkbox } from "antd";
import Notification from "../Notification";
import Loader from "../Loader";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { concatenatePaths } from "../../util/mapObject";
import getPathFromId from "../../util/getPathFromId";
import { useJnx } from "../../util/jnx";
import useOptionsLookup from "./hooks/useOptionsLookup";
import { makeTreeNode, organizeTreeNodes } from "../CollapsableTreeSelect";
import { convertIfNumber } from "../JsonTreeEditor/util";

const NONE_VALUE = '--None--';

function LookupMultiSelectFilterField(props) {
    const {
        formData,
        formContext,
        formContext: {
            sideChannel
        },
        disabled,
        readonly: propReadonly,
        schema: {
            title,
            lookup,
            type,
            categoriesSelectable = false,
        },
        idSchema: { $id } = {},
        uiSchema: {
            'ui:readonly': uiReadonly,
        },
        onChange,
    } = props;

    const readonly = propReadonly || uiReadonly;
    const dataPath = useMemo(() => getPathFromId($id), [$id]);
    const rootFormData = useSideChannelSubscription(sideChannel, 0);

    const {
        options: lookupOptions,
    } = useOptionsLookup({
        lookup,
        rootFormData,
        path: dataPath,
        formContext,
    });
    const isNumber = type === 'number';
    const [checked, setChecked] = useState(formData || []);

    useEffect(() => {
        setChecked(formData || []);
    }, [formData]);

    function isChecked(optionId) {
        return checked.indexOf(optionId) !== -1;
    }

    function toggleChecked(optionId, optionCheck) {
        // if (isNumber) { optionId = optionId | 0 }
        setChecked(prevChecked => {
            const newChecked = [...prevChecked];
            if (optionCheck) {
                const index = newChecked.indexOf(optionId);
                if (index > -1) {
                    newChecked.splice(index, 1);
                }
            } else {
                newChecked.push(optionId);
            }
            onChange(newChecked);
            console.log('newChecked', newChecked);
            return newChecked;
        });
    }

    const [options, optionsById] = useParsedOptions(lookupOptions, lookup);
    const isDisabled = readonly || disabled;

    const currentValue = useMemo(() => {
        if (checked.length > 0) {
            return checked.map(id => optionsById[id]).join(', ');
        }
        return null;
    }, [formData, optionsById])

    const renderOption = (option, ix, depth=0) => {
        return (
            <div style={{ paddingLeft: 8 }}>
                <Checkbox
                    key={`${option.id}-${ix}`}
                    checked={isChecked(option.id)}
                    onClick={() => toggleChecked(option.id, isChecked(option.id))}
                >
                    <div style={{ paddingLeft: 8 * depth }}>{option.label}</div>
                </Checkbox>
            </div>
        )
    }

    return (
        <div key={title}>
            <InputGroup className="filter-input-group">
                <InputGroupText className="filter-key">
                    {title}
                </InputGroupText>
                <UncontrolledDropdown className="filter-dropdown-menu shadow-none" disabled={isDisabled}>
                    <DropdownToggle className="shadow-none" caret>
                        <span className="worktray-filters-btn-title">
                            {currentValue || t`Select`}
                        </span>
                    </DropdownToggle>
                    <DropdownMenu className="filter-dropdown-menu"  style={{ maxHeight: 300, overflowY: "scroll", width: "max-content" }}>
                        <DropdownItem 
                            key={0} 
                            onClick={() => onChange(null)}
                            toggle={false}
                        >
                            {NONE_VALUE}
                        </DropdownItem>
                        {(options || []).map((o, ix) => renderOption(o, ix))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </InputGroup>
        </div>
    );
}

export function useParsedOptions(options, {
    parentId, id, label
}) {
    const tsOptions = useMemo(() => {
        if (!options) return [[], {}];

        const optionsById = {}
        const parsedOptions = options
            .map(item => {
                const node = makeTreeNode(item, id, label, parentId)
                optionsById[node.id] = node.label
                return node
            });

        if (parentId) {
            const rootNodes = organizeTreeNodes(parsedOptions);
            parsedOptions.sort((a, b) => a.visitIdx - b.visitIdx);
            return [rootNodes, optionsById];
        }

        return [parsedOptions, optionsById];
    }, [options, parentId]);

    return tsOptions;
}

export default LookupMultiSelectFilterField;